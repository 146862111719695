.portfolio-button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  margin-right: 2.5rem;

  padding: 0.7rem 2rem;
  width: auto;

  border: 0.1rem solid #2978b5;

  border-radius: 7px;

  font-size: 1rem;

  color: #2978b5;

  transition: 0.2s;
  margin: 1rem;
  /* margin-bottom: 1.5rem; */
}

.portfolio-buttons {
  text-align: center;
}

.portfolio-button:hover {
  background-color: #2978b5;
  color: #fcfcfc;
  transition: 0.2s;

  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transform: translateY(-0.25rem) scale(1.05);
}
