.portfolio-pages {
  display: flex;
  flex-direction: column;

  max-width: 75rem;
  min-width: 20rem;
  margin: 0rem 4rem 1.25rem;

  align-items: center;
}

.portfolio-item-title {
  font-size: 4rem;
  line-height: 4.75rem;
  text-align: left;
  margin: 2rem 0rem 0rem 0rem;
  color: #444444;
}

.portfolio-header {
  text-align: left;
  margin-right: auto;
}

.portfolio-page-title-line {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  margin: 0rem 0rem 1rem 0rem;
}

.portfolio-page-icon {
  box-sizing: border-box;
  object-position: 50% 50%;

  height: 7.5rem;
  max-width: 100%;

  object-fit: cover;

  /* border-radius: 0.8rem 0.8rem 0rem 0rem; */

  padding: 0rem;

  margin-left: auto;
}

.portfolio-sub-title {
  margin: 0.5rem 0rem 0.25rem;
  color: #656565;
}

.portfolio-page-buttons {
  margin-top: 1rem;
  margin-bottom: 2.5rem;
  display: flex;
}

.portfolio-page-contents p.sub-head-p {
  margin: 1.5rem 0rem 1.5rem 3rem;
}

.portfolio-page-contents p {
  text-align: left;

  font-weight: 400;
  font-size: 1.05rem;
  color: #282828;
  margin: 1.5rem 0rem 1.5rem 0rem;
  line-height: 1.65rem;
}

.portfolio-page-contents {
  text-align: left;
}

.portfolio-page-heading-1 {
  font-size: 2rem;
  margin: 2rem 0rem 0.2rem 0rem;
  text-align: left;
  color: #444444;
}

.portfolio-page-heading-2 {
  font-size: 1.45rem;
  margin: 2rem 0rem 0.2rem 0rem;
  text-align: left;
  color: #444444;
}

.portfolio-page-heading-2.line-class {
  border-top: 0.1rem solid rgb(220, 220, 220);

  padding-top: 1.5rem;
}

.portfolio-page-heading-3 {
  font-size: 1.2rem;
  margin: 1.5rem 0rem 0.5rem 3rem;
  text-align: left;
  color: #444444;
}

.portfolio-figure {
  text-align: center;
  display: table;

  table-layout: fixed;
  border-collapse: collapse;
  width: 30%;

  margin: 0rem auto;
}

.portfolio-figure-long {
  text-align: center;
  display: table;

  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;

  margin: 0rem auto;
}

.single-card {
  width: 70%;
}

.portfolio-figure figcaption {
  text-align: center;
  /* margin-left: 10%; */

  margin: 0rem 0rem;

  /* border-left: 3px solid black; */

  /* padding-left: 1rem; */

  font-size: 0.9rem;
  font-weight: 400;
  color: #4b4b4b;

  caption-side: bottom;
}

.portfolio-figure-long figcaption {
  text-align: center;
  /* margin-left: 10%; */

  margin: 0rem 0rem;

  /* border-left: 3px solid black; */

  /* padding-left: 1rem; */

  font-size: 0.9rem;
  font-weight: 400;
  color: #4b4b4b;

  caption-side: bottom;
}

.portfolio-image {
  text-align: center;

  margin: 0.5rem 0rem 0.25rem 0rem;

  height: 100%;

  width: 100%;

  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  object-fit: contain;

  padding: -1rem;

  /* border: 0.2rem solid black; */
  /* border-radius: 0.75rem;
  border: 0.1rem solid #4076b03e;
  box-shadow: rgba(9, 81, 126, 0.099) 0.1rem 0.1rem 01rem 0.1rem; */
}

.portfolio-image-long {
  text-align: center;

  margin: 1.5rem 0rem 1rem 0rem;

  height: 100%;

  width: 90%;

  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  object-fit: contain;

  padding: -1rem;

  /* border: 0.2rem solid black; */
  /* border-radius: 0.75rem;
  border: 0.1rem solid #4076b03e;
  box-shadow: rgba(9, 81, 126, 0.099) 0.1rem 0.1rem 01rem 0.1rem; */
}

.banner-image {
  text-align: center;

  margin: 1.5rem 0rem 1rem 0rem;

  height: 20rem;

  width: 100%;

  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  object-fit: cover;

  padding: -1rem;

  border-radius: 1rem;

  /* border: 0.2rem solid black; */
  /* border-radius: 0.75rem;
  border: 0.1rem solid #4076b03e;
  box-shadow: rgba(9, 81, 126, 0.099) 0.1rem 0.1rem 01rem 0.1rem; */
}

.portfolio-bullet-cont {
  list-style: none;
  margin-left: 1rem;
  padding-left: 1em;
  text-indent: -1em;

  text-align: left;

  font-weight: 400;
  font-size: 1.05rem;
  color: #282828;
  margin: 1.5rem 0rem 1.5rem 5rem;
  line-height: 1.65rem;
  list-style-position: outside;
}

.portfolio-bullet:before {
  content: "■";
  padding-right: 5px;

  text-align: left;

  font-weight: 800;
  font-size: 1.05rem;
  color: #2978b595;
  margin: 1.5rem 0rem 1.5rem 0rem;
  line-height: 1.65rem;
}

.portfolio-bullet {
  padding-left: 1rem;
  margin: 0.5rem 0rem;
}

.portfolio-figma {
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.1);
  justify-content: center;
  align-items: center;
  margin: 1.5rem auto;
  border-radius: 1rem;

  box-shadow: rgba(9, 81, 126, 0.098) 0.1rem 0.1rem 01rem 0.1rem;

  width: 80%;
  height: 500px;
}

.portfolio-figma.double {
  margin: 0rem 2rem;
}

.portfolio-double-figures {
  display: flex;
  margin-bottom: 2rem;
}

.portfolio-double-figures section {
  width: 45rem;
}

.disclaimer-text {
  border: 0.1rem solid #444444;
  border-radius: 0.35rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  margin: 1rem 0rem;
}

.disclaimer-text svg {
  padding-right: 1rem;
  font-size: 3rem;
  color: rgba(255, 0, 0, 0.693);
}

.disclaimer-text p {
  font-size: 1rem;
  color: #444444;
}

.problem-text {
  /* border: 0.1rem solid #444444; */
  background-color: rgba(181, 181, 181, 0.262);
  box-shadow: rgba(9, 81, 126, 0.098) 0.1rem 0.1rem 01rem 0.1rem;
  border-radius: 0.6rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  margin: 1rem 0rem;
  color: white;
}

.video {
  display: flex;
  width: 100%;
  margin: 1rem 0rem;
  border-radius: 0.6rem;
  box-shadow: rgba(9, 81, 126, 0.098) 0.1rem 0.1rem 01rem 0.1rem;
  justify-content: center;
  align-items: center;
  height: 40rem;
}

.video-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.portfolio-page-info {
  /* background-color: #d0d0d0; */
  /* width: 50%; */
  padding: 0.1rem 0rem;

  border-top: 0.1rem solid #4444441c;

  display: flex;
}

.portfolio-page-info section {
  margin-right: 3rem;
}

.portfolio-info-title {
  font-size: 1.5rem;
  margin: 0.75rem 0rem 0.5rem 0rem;
  color: #444444;
}

.portfolio-info-sub-title {
  font-size: 1rem;
  margin: 0.5rem 0rem 0.5rem 0rem;
  color: #656565;
}

.overview-grid {
  display: flex;
  flex-direction: row;
  gap: 2rem; /* Adjust the gap as needed */
}

.overview-grid-item {
  flex: 1;
  background-color: rgb(255, 255, 255);
  padding: 1rem 2rem;
  border-radius: 1rem;
  border: 0.075rem solid rgba(0, 0, 0, 0.229);
  box-shadow: rgba(92, 92, 114, 0) 0px 7px 29px 0px;
  margin: 1.25rem 0rem;
}

.overview-grid-item h3 {
  margin: 1rem 0rem;
  text-align: center;
}

.findings-grid-findings {
  display: grid;
  flex-direction: row;
  gap: 2.5rem; /* Adjust the gap as needed */
  margin: 2rem 0rem 2rem 3rem;
  grid-template-columns: 1fr 1fr;
}

.findings-overview-grid-item {
  flex: 1;
  /* background-color: rgb(255, 255, 255); */
  /* padding: 1rem 2rem; */
  /* border-radius: 1rem;
  border: 0.075rem solid rgba(0, 0, 0, 0.229);
  box-shadow: rgba(92, 92, 114, 0) 0px 7px 29px 0px; */
  margin: 1.25rem 0rem;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: row;
  color: white;
  margin: 0rem;
  min-width: 20rem;

  line-height: 1.3rem;
}

.findings-overview-grid-item h3 {
  margin: 0rem 0rem;
  text-align: center;
  color: white;
  padding: 5rem;
  align-items: center;
  font-size: 2.25rem;
  justify-content: center;
  height: 100%;
  flex: 1;
  display: flex;
  padding: 1rem 3rem;
}

.findings-overview-grid-item p {
  font-weight: 700;
  text-align: left;
  margin: 0rem 0rem 0rem 0rem;
  font-size: 1rem;
}

.grid-square {
  width: 80px;
  height: 80px;
  background-color: #2978b5;
  border-radius: 1rem;

  display: flex;
  align-items: center;
  flex-direction: column;
  margin-right: 1rem;
}

.in-double {
  margin-left: 3rem;
}

.work-in-progress-container {
  display: flex;
  align-items: center;
  flex-direction: column;

  /* border-top: 0.1rem solid rgb(225, 225, 225); */

  margin-top: 1rem;

  /* background-color: #ffffff; */

  /* border: 0.075rem solid rgba(0, 0, 0, 0.229); */
  box-shadow: rgba(92, 92, 114, 0) 0px 7px 29px 0px;

  border-top: 0.5rem solid pink;
  border-image: repeating-linear-gradient(
      -55deg,
      #000,
      #000 20px,
      #ffb101 20px,
      #ffb101 40px
    )
    5;
}

.work-in-progress-container h2 {
  margin: 1rem 0rem 0rem 0rem;
}

.work-in-progress-image {
  margin: 1rem;
  height: 20rem;
}

.work-in-progress-container section {
  margin: 0.25rem 0rem 1rem 0rem;
  text-align: center;
}

.work-in-progress-container section p {
  margin: 0.25rem;
  text-align: center;
  color: #444444;
}

.portfolio-video {
  display: block;
  margin: 0 auto;

  margin-top: 1rem;
  margin-bottom: 1rem;
}

/* Response View */

@media only screen and (max-width: 52rem) {
  .portfolio-item-title {
    font-size: 3rem;
    text-align: center;
    margin: 1rem 0rem 1rem 0rem;
    line-height: 3.5rem;
  }

  .portfolio-page-title-line {
    flex-direction: column-reverse;
    margin-top: 1rem;
  }

  .portfolio-page-icon {
    margin: 0rem;
  }

  .portfolio-page-contents p {
    text-align: justify;
    margin-bottom: 1.5rem;
    font-size: 1rem;
    color: hsl(0, 0%, 2%);
    /* line-height: 1.8rem; */
  }

  .portfolio-page-contents p.sub-head-p {
    margin: 1.5rem 0rem 1.5rem 0rem;
  }

  .portfolio-pages {
    max-width: 100%;
    height: 100%;
    max-width: 30rem;
    text-align: center;
    margin: 0rem 2rem 2.5rem 2rem;
    min-width: 17rem;
  }

  .portfolio-page-buttons {
    justify-content: space-evenly;
    margin-bottom: 0rem;
  }

  .portfolio-sub-title {
    text-align: center;
    margin: 0rem 0rem 0rem 0rem;

    line-height: 1.5rem;
  }

  .portfolio-double-figures {
    flex-direction: column;
  }

  .portfolio-figure {
    width: 100%;

    margin: 0rem auto;
  }

  .portfolio-figure-long {
    width: 95%;

    margin: 0rem auto;
  }

  .portfolio-page-heading-1 {
    font-size: 1.9rem;
    margin: 1.25rem 0rem 1.5rem 0rem;
    text-align: center;
  }

  .portfolio-page-heading-2 {
    font-size: 1.5rem;
    margin: 1rem 0rem 0.5rem 0rem;
    text-align: center;
  }

  .portfolio-page-heading-3 {
    font-size: 1.25rem;
    margin: 1rem 0rem 0.5rem 0rem;
    text-align: center;
  }

  .disclaimer-text {
    padding: 0.5rem 1rem 0.5rem 0rem;
    display: flex;
    align-items: center;
    margin: 1rem 0rem 2rem 0rem;
  }

  .disclaimer-text svg {
    padding-right: 0rem;
    font-size: 3rem;
    color: rgba(255, 0, 0, 0.693);
  }

  .disclaimer-text p {
    font-size: 0.9rem;
    color: #444444;
  }

  .video {
    width: 100%;
    height: 250px;
  }

  .portfolio-bullet-cont {
    margin: 1rem 0rem;
  }

  .portfolio-figma.double {
    margin: 1rem 0rem;
  }

  .portfolio-figma {
    width: 100%;
  }

  .portfolio-bullet {
    padding-left: 0rem;
  }

  .banner-image {
    text-align: center;

    margin: 1.5rem 0rem 1rem 0rem;

    height: 12rem;

    width: 90%;

    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
    object-fit: cover;

    padding: -1rem;

    border-radius: 1rem;

    /* border: 0.2rem solid black; */
    /* border-radius: 0.75rem;
    border: 0.1rem solid #4076b03e;
    box-shadow: rgba(9, 81, 126, 0.099) 0.1rem 0.1rem 01rem 0.1rem; */
  }

  .portfolio-page-info {
    /* background-color: #d0d0d0; */
    /* width: 50%; */
    padding: 0.1rem 0rem;

    border-top: 0.1rem solid #4444441c;

    display: flex;
    flex-direction: column;
    margin: 0rem 0rem 1.5rem 0rem;
    justify-content: space-between;
    align-items: center;
  }

  .portfolio-page-info section {
    margin-right: 0rem;
    align-items: center;
    max-width: 10rem;
    text-align: center;
  }

  .portfolio-info-title {
    font-size: 1.2rem;
    margin: 1rem 0rem 0.5rem 0rem;
    color: #444444;
  }

  .portfolio-info-sub-title {
    font-size: 0.8rem;
    margin: 0.5rem 0rem 0.5rem 0rem;
    color: #656565;
  }

  .overview-grid {
    display: flex;
    flex-direction: column;
    gap: 0rem; /* Adjust the gap as needed */
  }

  .portfolio-double-figures section {
    width: auto;
  }

  .findings-grid-findings {
    grid-template-columns: 1fr;
    margin-left: 0rem;
  }

  .in-double {
    margin-left: 0rem;
  }

  .findings-overview-grid-item p {
    margin: 0rem 0rem 0rem 0rem;
    line-height: 1.4rem;
    text-align: left;
    font-weight: 400;
  }

  .portfolio-double-figures {
    align-items: center;
    justify-content: center;
  }

  .findings-overview-grid-item {
    line-height: 1.3rem;
  }

  .work-in-progress-container {
    display: flex;
    align-items: center;
    flex-direction: column;

    /* border-top: 0.1rem solid rgb(225, 225, 225); */

    margin-top: 1rem;

    /* background-color: #ffffff; */

    /* border: 0.075rem solid rgba(0, 0, 0, 0.229); */
    box-shadow: rgba(92, 92, 114, 0) 0px 7px 29px 0px;

    border-top: 0.5rem solid pink;
    border-image: repeating-linear-gradient(
        -55deg,
        #000,
        #000 20px,
        #ffb101 20px,
        #ffb101 40px
      )
      5;
  }

  .work-in-progress-container h2 {
    margin: 1rem 0rem 0rem 0rem;
  }

  .work-in-progress-image {
    margin: 1rem;
    height: 15rem;
  }

  .work-in-progress-container section {
    margin: 0.25rem 0rem 1rem 0rem;
    text-align: center;
  }

  .work-in-progress-container section p {
    margin: 0.25rem;
    text-align: center;
    color: #444444;
  }

  .portfolio-video {
    width: 80%;
  }
}
