.research-item-title{
    font-size: 2.75rem;
    line-height: 4rem;
    text-align: left;
    margin: 2rem 0rem 0rem 0rem;
    color: #444444;
}

.research-page-title-line{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
    margin: 0rem 0rem 1rem 0rem;
}

.research-sub-title{
    margin: 0.5rem 0rem 0.25rem;
    color: #656565;
}

@media only screen and (max-width: 52rem) {
    .research-item-title{
        font-size: 2.25rem;
        text-align: center;
        margin: 1rem 0rem 1rem 0rem;
        line-height: 3.5rem;
    }

    .research-page-title-line{
        flex-direction: column-reverse;
        margin-top: 1rem;
    }

    .research-sub-title{
        text-align: center;
        margin: 0rem 0rem 1.5rem 0rem;
        font-size: 1.1rem;
    }
}