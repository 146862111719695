#root {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 5.25rem);
  margin: 0rem;
  align-items: center;
}

main {
  max-width: 100vw;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Poppins", sans-serif;
  margin: 0rem;
  width: 100%;
  min-height: 100vh;
  text-align: center;
  font-weight: 500;
  font-size: 1.2rem;

  display: flex;
  flex-direction: column;

  background-color: #fcfcfc;

  box-sizing: border-box;
  padding-top: 5.25rem;

  height: 100%;

  min-height: 100vh;

  flex: auto;
}

a {
  color: #555555;
  background-color: transparent;
  text-decoration: none;
  font-size: 1rem;

  display: inline-block;
}

.page-title {
  font-size: 4rem;
  line-height: 4.75rem;
  text-align: left;
  margin: 2rem 0rem 2.5rem 0rem;
  color: #444444;
}

@media only screen and (max-width: 52rem) {
  .page-title {
    font-size: 3rem;
    text-align: center;
    margin: 1rem 0rem 1.5rem 0rem;
  }
}
