.about-page {
  margin: 0rem 5rem;
  display: flex;

  flex-direction: column;

  max-width: 75rem;
  min-width: 20rem;

  margin: 0rem 4rem 0.7rem;
}

.bio-bold {
  color: #2978b5;
}

.bio-bold-yellow {
  color: #ef8317;
}

.about-desc img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  object-fit: cover;

  max-width: 80%;
  height: auto;

  margin: 0rem 2.5rem 0rem 0rem;

  border-radius: 20px;

  box-shadow: rgba(92, 92, 114, 0.4) 0px 7px 29px 0px;
}

.about-desc {
  display: flex;
  flex-direction: row;

  text-align: left;

  margin: 0rem 0rem 2rem 0rem;
}

.about-left-column {
  flex: 0.8;
  text-align: center;
}

.body-text {
  margin: 0rem;
  font-weight: 400;
  font-size: 1.05rem;
  color: #393939;

  margin: 0rem 0rem 1rem 0rem;

  line-height: 1.65rem;
}

.about-location {
  margin: 0.6rem 2.5rem 0rem 0rem;
  font-weight: 400;
  font-size: 1.1rem;
  color: #444444d8;

  display: flex;

  justify-content: center;
  align-items: center;
}

.about-skills,
.about-education {
  margin: 1rem 0rem;
  border-top: 0.1rem solid rgba(215, 215, 215, 0.305);
}

.about-skill-block {
  margin: 0.4rem;
}

.about-skill-block img {
  max-width: 6rem;
  max-height: 6rem;

  border-radius: 20px;

  box-shadow: rgba(92, 92, 114, 0.4) 0px 7px 29px 0px;
}

.about-skill-block p {
  margin: 0.3rem 0rem;
  font-weight: 400;
  font-size: 1.1rem;
}

.page-h3-title {
  text-align: left;
  color: #444444da;
  font-size: 1.85rem;
  margin: 0rem 0rem 2rem 1rem;
}

.education-block {
  border: 0.075rem solid rgba(0, 0, 0, 0.229);
  border-radius: 1rem;

  box-shadow: rgba(92, 92, 114, 0) 0px 7px 29px 0px;

  display: flex;
  flex-direction: row;

  margin: 0rem 0rem 1rem 0rem;

  text-align: left;

  padding: 1rem;
  background-color: rgb(255, 255, 255);
}

.education-block img {
  max-width: 6rem;
  max-height: 6rem;
  margin: 0rem 1rem 0rem 0rem;

  border-radius: 1rem;

  box-shadow: rgba(255, 255, 255, 0.243) 0px 7px 29px 0px;

  align-items: center;
  justify-content: center;
}

.education-block-title {
  font-size: 1.25rem;
  font-weight: 500;

  margin: 0rem 0rem 0rem 0rem;
  color: #444444;
}

.education-block-info p {
  margin: 1rem 0rem 0rem 0rem;

  font-size: 1rem;
  font-weight: 400;

  color: #666666;

  margin: 0.2rem 0rem 0rem 0rem;
}

.education-block-info time {
  margin: 1rem 0rem 0rem 0rem;

  padding: 0rem;

  font-size: 0.9rem;
  font-weight: 400;

  color: #8e8e8e;

  position: relative;

  margin-top: auto;
}

.education-block-info {
  display: flex;
  flex-direction: column;
}

.about-right-column {
  flex: 1 2;
}

.skill-list-grid {
  width: 100%;
  max-width: 100%;

  display: inline-grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-rows: minmax(100vh, autofit);
  gap: 1.5rem;

  justify-items: center;

  margin-bottom: 1.25rem;
}

/* Response View */

@media only screen and (max-width: 64rem) {
  .skill-list-grid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .body-text {
    font-size: 1rem;

    margin-bottom: 0.9rem;
  }

  .education-block {
    padding: 0.5rem;
  }

  .education-block-title {
    font-size: 1.05rem;
  }

  .education-block-info p {
    font-size: 0.85rem;

    margin: 0.1rem 0rem 0rem 0rem;
  }

  .education-block-info time {
    font-size: 0.8rem;
  }
}

@media only screen and (max-width: 52rem) {
  .skill-list-grid {
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;
  }

  .about-desc {
    flex-direction: column;
    margin-bottom: 0.5rem;
  }

  .body-text {
    text-align: justify;
    margin-bottom: 1.5rem;

    color: hsl(0, 0%, 2%);

    line-height: 1.8rem;
  }

  .about-location {
    margin: 0.85rem 0rem 1.3rem 0rem;

    font-size: 1rem;
  }

  .about-page {
    /* width: 100%; */
    height: 100%;
    max-width: 30rem;

    text-align: center;
    margin: 0rem 2rem 2.5rem 2rem;

    min-width: 17rem;
  }

  .about-left-column img {
    text-align: center;
    margin: 0rem;
  }

  .about-desc img {
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
    object-fit: contain;

    max-width: 100%;
    max-height: 100%;
  }

  .about-skills,
  .about-education {
    margin: 1rem 0rem;
    border-top: 0.1rem solid rgba(215, 215, 215, 0.305);
  }

  .about-skill-block {
    margin: 0.8rem;
  }

  .about-skill-block img {
    max-width: 5.5rem;
    max-height: 5.5rem;
  }

  .about-skill-block p {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 34rem) {
  .education-block {
    flex-direction: column;
    align-items: center;
  }

  .education-block img {
    margin: 0.5rem;
    width: 5rem;
    height: 5rem;
  }

  .education-block-info {
    align-items: center;
    text-align: center;
  }

  .education-block-info time {
    margin-top: 0.75rem;
  }
}
