/* Desktop View */
.portfolio-card {
  margin: 1rem 0.5rem;
  text-align: left;

  border-radius: 0.8rem;
  background-color: #ffffff;

  height: 26.5rem;

  max-width: 20rem;
  min-width: 15rem;

  box-shadow: rgba(50, 50, 93, 0.081) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.216) 0px 3px 7px -3px;
  transition: all 0.2s ease-out;
}

.portfolio-card:hover {
  transform: translate(-0rem, -0.25rem);

  box-shadow: rgba(50, 50, 93, 0.228) 0px 50px 100px -20px,
    rgba(47, 41, 97, 0.205) 0px 30px 60px -30px;
}

.portfolio-card-image {
  box-sizing: border-box;
  object-position: 50% 50%;

  height: 15rem;
  max-width: 100%;
  min-width: 12rem;

  object-fit: cover;

  border-radius: 0.8rem 0.8rem 0rem 0rem;

  padding: 0rem;

  clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);

  margin-bottom: 1.5rem;
}

.portfolio-card-content {
  color: #353535;

  margin: 0.5rem 1rem;
}

.portfolio-card-content header {
  display: inline;
}

.portfolio-card-content h2 {
  padding: 0rem;
  margin: 0.6rem 0rem 0rem 0rem;

  line-height: 1.8rem;
  font-size: 1.4rem;
  color: #353535;
  font-weight: 600;
}

.portfolio-case-section {
  /* display: flex; */
  align-items: center;

  justify-content: center;

  max-width: 20.5rem;
  min-width: 12rem;
  height: 14rem;
  /* background: rgb(221, 251, 253); */

  clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);

  border-radius: 0.8rem 0.8rem 0rem 0rem;

  padding: 0rem;
}

/* .gradient-green {
  background: linear-gradient(
    27deg,
    rgba(221, 251, 253, 1) 0%,
    rgba(160, 208, 132, 1) 82%,
    rgba(93, 161, 1, 1) 100%
  );
}

.gradient-pink {
  background: linear-gradient(
    27deg,
    rgba(221, 251, 253, 1) 0%,
    rgba(232, 97, 175, 1) 82%,
    rgba(255, 38, 192, 1) 100%
  );
}

.gradient-blue {
  background: linear-gradient(
    115deg,
    rgba(221, 251, 253, 1) 0%,
    rgba(97, 209, 232, 1) 82%,
    rgba(38, 141, 255, 1) 100%
  );
} */

.gradient-yellow {
  background: rgb(246, 253, 221);
  background: linear-gradient(
    115deg,
    rgba(246, 253, 221, 1) 0%,
    rgba(216, 232, 97, 1) 82%,
    rgba(234, 255, 38, 1) 100%
  );
}

.gradient-grey {
  background: rgb(246, 246, 246);
  background: linear-gradient(
    115deg,
    rgba(246, 246, 246, 1) 0%,
    rgba(184, 184, 184, 1) 82%,
    rgba(129, 129, 129, 1) 100%
  );
}

.project-type {
  border-radius: 6px;

  margin: 10rem 0rem;
  display: inline;

  font-weight: 400;
  font-size: 0.9rem;
}

.web-dev {
  color: #0192e6;
}

.game-dev {
  color: #9a01e6;
}

.ux-case-study {
  color: #0192e6;
}

.research-type {
  color: #9f4515;
}

.portfolio-card-desc {
  font-weight: 400;
  margin: 0.7rem 0rem 0.85rem 0rem;
  font-size: 0.925rem;
  line-height: 1.33rem;

  color: #353535b0;
}

.card-grid {
  width: 85vw;

  display: inline-grid;
  /* grid-template-columns: 1fr 1fr 1fr; */
  /* grid-template-columns: 1fr; */
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: minmax(100vh, autofit);
  gap: 1rem;

  justify-items: center;

  margin-bottom: 1rem;
}

.research-card-grid {
  display: inline-grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: minmax(100vh, autofit);
  gap: 8rem;

  justify-items: center;
  margin-bottom: 2rem;
}

/* Response View */

@media only screen and (max-width: 64rem) {
  .card-grid,
  .research-card-grid {
    grid-template-columns: 1fr 1fr;
    gap: 1rem 2rem;
    /* max-width: 85%; */
  }

  .portfolio-card {
    min-width: 18rem;
  }
}

@media only screen and (max-width: 52rem) {
  .card-grid,
  .research-card-grid {
    grid-template-columns: 1fr;
    gap: 0.5rem;
    margin-bottom: 1rem;
    /* max-width: 80%; */
  }
  .portfolio-case-section {
    max-width: 20.5rem;
    min-width: 20rem;
  }

  .portfolio-card {
    min-width: 17rem;

    margin: 1rem 0rem;
  }
}

@media only screen and (max-width: 34rem) {
  .portfolio-case-section {
    max-width: 20.5rem;
    min-width: 17rem;
  }

  .portfolio-card-content h2 {
    line-height: 1.5rem;
    font-size: 1.2rem;
    margin-bottom: 0.08rem;
  }

  .project-type {
    font-size: 0.85rem;
  }

  .portfolio-card-desc {
    font-size: 0.85rem;
    line-height: 1.25rem;
    margin-top: 0.6rem;
  }
}
