/* Desktop View */

.homepage-card {
  display: inline-flex;
  flex-direction: row;
  text-align: left;

  max-width: 75rem;
  min-width: 20rem;

  align-items: center;

  margin: 0rem 4rem 0.7rem;
}

.hero-name {
  color: #2978b5;
}

.homepage-card-content {
  margin: 0rem;
  font-weight: 400;

  font-size: 1.25rem;

  position: relative;

  color: #444444eb;

  line-height: 2rem;
}

.homepage-card-content p {
  margin: 0rem;
}

.homepage-card-image,
.homepage-card-info {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.homepage-card-image {
  align-items: center;
  height: 100%;
  /* padding: 3% 2%; */

  position: relative;
  animation: floatUpDown 7s ease-in-out infinite;

  margin-left: auto;
  margin-right: auto;

  padding: 0rem;
}

@keyframes floatUpDown {
  0%,
  100% {
    transform: translateY(1rem);
  }
  50% {
    transform: translateY(
      -1rem
    ); /* Adjust this value for the desired floating range */
  }
}

.homepage-card-image video {
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  object-fit: cover;

  max-width: 93%;
  height: auto;

  margin: auto;

  border-radius: 20px;

  /* box-shadow: rgba(92, 92, 114, 0.4) 0px 7px 29px 0px; */
}

.homepage-card-info h1 {
  font-size: 4rem;
  line-height: 4.75rem;

  justify-content: center;
  align-items: center;

  margin: 0rem -10rem 0.8rem 0rem;

  color: #444444;
}

.homepage-card-image img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  object-fit: cover;

  max-width: 70%;
  height: auto;

  border-radius: 20px;

  /* Re-add if using photo */
  /* box-shadow: rgba(92, 92, 114, 0.4) 0px 7px 29px 0px; */
}

.homepage-list-item {
  list-style-type: square;
}

.page-sub-title {
  font-size: 2.5rem;
  margin: 2rem 0rem 2rem 0rem;
  text-align: left;
  color: #444444;
}

/* Response View */

@media only screen and (max-width: 64rem) {
  .homepage-card {
    margin: 0rem 2rem 0rem 2rem;
  }

  .homepage-card-info h1 {
    font-size: 3.5rem;
    line-height: 3.5rem;

    margin: 0rem -4rem 1rem 0rem;
  }

  .homepage-card-content {
    font-size: 1rem;
    line-height: 1.75rem;
  }

  /* .homepage-card-image {
    padding: 3% 1%;
  } */

  .homepage-card-info {
    padding: 1rem 1rem 1rem 2rem;
  }

  .homepage-card-image img {
    max-width: 65%;
    height: 65%;
  }
}

@media only screen and (max-width: 52rem) {
  .homepage-card,
  .homepage-card-reverse {
    margin: 0rem 0rem;

    min-width: 2rem;

    margin: 1.5rem 2rem 2rem 2rem;
  }

  .homepage-card {
    flex-direction: column-reverse;
  }

  .homepage-card-reverse {
    flex-direction: column;
  }

  .homepage-card-info h1 {
    font-size: 2.2rem;
    line-height: 2.5rem;

    margin: 0.3rem -2rem 1.25rem -2rem;
    text-align: center;
  }

  .homepage-card-buttons {
    display: flex;
    flex-direction: column;
    margin: 1rem 0rem 0rem 0rem;
  }

  .homepage-card-content {
    text-align: center;
  }

  .homepage-card-info {
    padding: 0rem;
    width: 100%;
  }

  .homepage-card-content {
    font-size: 1.1rem;

    margin: 0rem 0rem;
  }

  /* .homepage-card-image {
    padding: 1rem 0rem 1.5rem 0rem;
  } */

  .homepage-card-image img {
    max-width: 80%;
    max-height: 100%;

    /* max-width: 30rem; */
  }

  .homepage-card-image video {
    margin-bottom: -0.5rem;
  }

  .page-sub-title {
    font-size: 2rem;
    margin: 2rem 0rem 1.5rem 0rem;
    text-align: center;
  }

  @keyframes floatUpDown {
    0%,
    100% {
      transform: translateY(0.5rem);
    }
    50% {
      transform: translateY(
        -0.5rem
      ); /* Adjust this value for the desired floating range */
    }
  }
}
