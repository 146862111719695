.header-nav {
  top: 0;
  position: fixed;
  width: 100%;
  transform-style: preserve-3d;
  z-index: 2;
}

.navbar {
  display: flex;
  align-items: center;
  padding: 0rem 0rem 0rem 1.5rem;
  height: 5.25rem;

  background-color: #fcfcfc;
}

.scroll {
  box-shadow: 0 8px 6px -8px rgba(195, 195, 195, 0.541);
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: bold;
  color: #2978b5;
}

.navigation,
.nav-list {
  display: inline-flex;
  padding: 0rem 0.625rem;
  list-style-type: none;
  margin-left: auto;

  align-items: center;

  text-align: -webkit-center;
}

.nav-item {
  transition: all 0.2s linear;
  padding: 1.5rem 0rem;
  margin: 0rem 1rem;

  display: inline-block;
  position: relative;

  font-weight: 600;
}

.nav-item::before {
  transition: all 0.2s ease-out;
  height: 0.2rem;
  content: "";
  position: absolute;
  background-color: #555555;

  width: 100%;
  bottom: 0.3rem;
  opacity: 0;
}

.hamburger-menu {
  display: none;
  padding: 1rem;
}

/* Media query that disables persistent hover effects when clicked on mobile */
@media (hover: hover) {
  .nav-item:hover {
    color: #2978b5;
  }

  .nav-item:hover::before {
    bottom: 1rem;
    opacity: 1;
    background-color: #2978b5;
  }
}

@media (max-width: 47.9rem) {
  .navbar {
    padding: 0rem;
    height: 4.8rem;
  }

  .logo {
    margin-left: 0.5rem;
  }

  /* .scroll {
    box-shadow: none;
  } */

  .nav-list {
    width: 100%;
    height: auto;
    position: absolute;
    top: -20rem;
    left: 0%;
    pointer-events: none;
    transition: all 0.5s cubic-bezier(0.55, 0, 1, 0.45);
    flex-direction: column;

    transform: translateZ(-1rem);
    z-index: -1;
    margin: 0rem;

    padding: 0rem;
    opacity: 1;

    align-items: stretch;
  }

  .navigation {
    z-index: -1;
  }

  .nav-list.active {
    background-color: white;
    top: 4.8rem;
    left: 0%;
    transition: all 0.5s cubic-bezier(0, 0.55, 0.45, 1);
    align-content: center;
    padding-left: 0rem;
    pointer-events: all;

    /* border: 1rem solid black; */
  }

  .menu-icon {
    width: 2rem;
    height: 2rem;

    filter: invert(32%) sepia(0%) saturate(0%) hue-rotate(175deg)
      brightness(99%) contrast(93%);
  }

  .google-login-button-nav {
    padding: 1.75rem 0rem;

    width: 100%;

    border: 0rem;

    font-weight: bold;
    border-radius: 0rem;

    margin: 0rem;
  }

  .nav-item,
  .google-login-button-nav {
    border-bottom: 0.12rem solid rgb(197, 197, 197);
    background-color: #fcfcfc;
  }

  .nav-list:first-child {
    border-top: 0.12rem solid rgb(197, 197, 197);
  }

  .nav-list:last-child {
    box-shadow: 0 8px 6px -8px rgba(110, 109, 109, 0.541);
  }

  .nav-item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.7rem 0rem;

    margin: 0rem;
  }

  .nav-item:hover::before {
    bottom: 0rem;
    opacity: 0;
  }

  .hamburger-menu {
    display: block;
  }

  .google-login-button-nav:hover {
    transform: translate(0rem, 0rem);
    box-shadow: none;
    cursor: pointer;
  }

  .login-icon {
    filter: invert(0);
  }
}
