.hero-button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  margin-top: 4rem;

  margin-right: 2.5rem;

  padding: 0.7rem 2rem;

  border: 0.1rem solid #2978b5;

  border-radius: 7px;

  font-size: 1rem;

  color: #2978b5;

  transition: 0.2s;
}

.hero-button:hover {
  background-color: #2978b5;
  color: #fcfcfc;
  transition: 0.2s;

  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transform: translateY(-0.25rem) scale(1.05);
}

/* Response View */

@media only screen and (max-width: 64rem) {
  .hero-button {
    margin-top: 2.2rem;

    margin-right: 1.5rem;

    padding: 0.65rem 1.8rem;
  }
}

@media only screen and (max-width: 52rem) {
  .hero-button {
    text-align: center;
    margin: 1rem 0rem;
  }
}
