.portfolio-grid {
  width: 100%;
  max-width: 100%;

  display: inline-grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: minmax(100vh, autofit);
  gap: 2rem;

  justify-items: center;

  margin-bottom: 1.25rem;
}

.portfolio-page {
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
}

.portfolio-page-header {
  text-align: left;
  margin-right: auto;
}

@media only screen and (max-width: 52rem) {
  .portfolio-page {
    align-items: center;
    /* width: 30rem; */
  }

  .portfolio-page-header {
    text-align: center;
    margin-right: 0rem;
  }
}
